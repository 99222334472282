import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
// import Disqus from 'gatsby-plugin-disqus'

import Layout from "../components/layout"
import SEO from "../components/seo"

// temp off
/*
          <Disqus 
            identifier={post.fields.slug}
            title={post.frontmatter.title}
            url={`${site.siteMetadata.siteUrl}${post.fields.slug}`}
          />
*/

export default function Template({
  data, // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { markdownRemark: post, site } = data // data.markdownRemark holds our post data
  console.log('blog post data', data)
  return (
    <Layout headerImage={post.frontmatter.featuredImage.childImageSharp.fluid}>
      <SEO title={post.frontmatter.title} keywords={[`Tangerine Fix`, `coffee`]} />
      <div className="blog-post-container">
        <Helmet title={`${site.siteMetadata.title} - ${post.frontmatter.title}`} />
        <div className="blog-post">
          <h1>{post.frontmatter.title}</h1>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }

`
